import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  renderBody = () => {
    return (
      <div>
        {data.body.map((paragraph) => {
          return <p>{paragraph}</p>;
        })}
      </div>
    );
  };

  render() {
    return (
      <div>
        <Header />
        <div className="topTitle">
          <h1>{data.title}</h1>
        </div>
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                </div>

                <div className="section-main-description">

                <p>Eastman provides support to deployed U.S. military service members and their families throughout the year through its Supplies for Soldiers program. The program provides care packages and other support to service members who are deployed, including Eastman employees and family members as well as local National Guard members and reservists.</p>

<p>EVETS, Eastman’s resource group for veterans, manages the program and collaborates with employee groups, community organizations, local businesses, volunteers and the <a href="https://www.eastman.com/en/sustainability/social/csr/foundation">Eastman Foundation</a> to collect personal care items, books, bedding, snacks, batteries and a variety of other items for care packages as well as monetary donations to purchase needed items.</p>

<p>More than 50,000 items were collected and nearly $15,000 in donations received during the 2022 Supplies for Soldiers campaign.</p>

<p>Thanks for your support. This means the world to our service members!</p>
                  <br />
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate />
              <DonationOptions />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
